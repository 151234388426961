exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-assistance-tsx": () => import("./../../../src/pages/assistance.tsx" /* webpackChunkName: "component---src-pages-assistance-tsx" */),
  "component---src-pages-care-tsx": () => import("./../../../src/pages/care.tsx" /* webpackChunkName: "component---src-pages-care-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-example-tsx": () => import("./../../../src/pages/example.tsx" /* webpackChunkName: "component---src-pages-example-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nurses-tsx": () => import("./../../../src/pages/nurses.tsx" /* webpackChunkName: "component---src-pages-nurses-tsx" */),
  "component---src-pages-rehabilitation-tsx": () => import("./../../../src/pages/rehabilitation.tsx" /* webpackChunkName: "component---src-pages-rehabilitation-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

